//===-------//
// Home path
//-------===//

//===-----------------===
// Home animation fill
//===-----------------===
if (document.querySelector(".cs-journey")) {
	let mm = gsap.matchMedia()
	const path = document.querySelector(".journey-svg-path"),
		length = path.getTotalLength()

	path.style.strokeDasharray = length
	path.style.strokeDashoffset = length

	const updateDrawing = progress => {
			const drawLength = length * progress

			gsap.set(path, {
				strokeDashoffset: length - drawLength,
			})

			const point = path.getPointAtLength(drawLength)

			mm.add("(min-width: 768px)", () => {
				gsap.set(".celeste-circle", {
					x: point.x - 30,
					y: point.y - 30,
				})
				if (progress === 1) {
					gsap.set(".celeste-circle", {
						opacity: 0,
					})
					gsap.set("#journey-svg-path-arrow", {
						stroke: "#1392cd",
					})

					gsap.set(".celeste-circle-phone", {
						x: 129,
						y: 120,
						opacity: 1,
					})
				}
				if (progress < 1) {
					gsap.set("#journey-svg-path-arrow", {
						stroke: "#d6dcdc",
					})
					gsap.set(".celeste-circle", {
						x: point.x - 30,
						y: point.y - 30,
						scale: 1,
						opacity: 1,
					})

					gsap.set(".celeste-circle-phone", {
						x: 129,
						y: 120,
						opacity: 0,
					})
				}
			})
		},
		animateBubbles = progress => {
			const circleRect = document
					.querySelector(".celeste-circle")
					.getBoundingClientRect(),
				circleTop =
					(circleRect.top + window.scrollY + circleRect.height / 2) * 1.4,
				circleLeft = circleRect.left + window.scrollX + circleRect.width / 2,
				bubbles = document.querySelectorAll(".bubble")

			bubbles.forEach(bubble => {
				mm.add("(min-width: 768px)", () => {
					const bubbleRect = bubble.getBoundingClientRect(),
						bubbleTopPos = bubbleRect.top + window.scrollY,
						bubbleLeftPos = bubbleRect.left + window.scrollX,
						bubbleLeft = bubbleLeftPos + bubbleRect.width / 2,
						bubbleTop = bubbleTopPos + bubbleRect.height / 2,
						topDiff = circleTop - bubbleTop, // y
						leftDiff = circleLeft - bubbleLeft // x

					let xOffset = leftDiff * progress,
						yOffset = topDiff * progress * 1.35

					gsap.set(bubble, {
						x: xOffset,
						y: yOffset,
					})
				})
			})
		},
		updateMobileDrawing = progress => {
			const mobilePath = document.querySelector(".journey-svg-path-mobile"),
				length = mobilePath.getTotalLength()

			mobilePath.style.strokeDasharray = length
			mobilePath.style.strokeDashoffset = length

			const drawLength = length * progress

			gsap.set(mobilePath, {
				strokeDashoffset: length - drawLength,
			})

			const point = mobilePath.getPointAtLength(drawLength)

			if (progress > 0.2) {
				gsap.set(".journey-svg-mobile .celeste-circle", {
					x: point.x - 25,
					y: point.y - 25,
					autoAlpha: 1,
					scale: 0.75,
				})
			}

			if (progress === 1) {
				gsap.set(".celeste-circle", {
					opacity: 0,
				})
				gsap.set("#journey-svg-path-arrow-mobile", {
					stroke: "#1392cd",
				})

				gsap.set(".celeste-circle-phone", {
					x: 129,
					y: 120,
					opacity: 1,
				})
			}
			if (progress < 1) {
				gsap.set("#journey-svg-path-arrow-mobile", {
					stroke: "#d6dcdc",
				})

				gsap.set(".celeste-circle-phone", {
					x: 129,
					y: 120,
					opacity: 0,
				})
			}
		}
	ScrollTrigger.create({
		trigger: ".cs-journey",
		start: "top center",
		end: "bottom bottom",
		scrub: true,
		onUpdate: self => {
			mm.add("(min-width: 768px)", () => {
				updateDrawing(self.progress)
			})

			mm.add("(max-width: 767px)", () => {
				updateMobileDrawing(self.progress)
			})
		},
	})

	ScrollTrigger.create({
		trigger: "#cs-journey__disconnected-title",
		start: "top center",
		end: "bottom center",
		scrub: 3,
		onUpdate: self => {
			// put if statement for progress to start the animation
			const bubbles = document.querySelectorAll(".bubble")
			if (self.progress >= 0.05) {
				animateBubbles(self.progress)
			}

			mm.add("(min-width: 768px)", () => {
				if (self.progress >= 0.6) {
					bubbles.forEach(bubble => {
						bubble.style.opacity = "0"
					})
				} else {
					bubbles.forEach(bubble => {
						bubble.style.opacity = "1"
					})
				}
			})

			//figure out where to stop the animation to change the opacity to 0
		},
	})

	mm.add("(min-width: 768px)", () => {
		gsap.to("#cs-journey__disconnected-title", {
			scrollTrigger: {
				trigger: "#cs-journey__disconnected-title",
				pin: "#cs-journey__disconnected-title h3",
				start: "top center",
				end: "bottom +=120%",
			},
		})

		gsap.to("#cs-journey__connected-title", {
			scrollTrigger: {
				trigger: "#cs-journey__connected-title",
				pin: "#cs-journey__connected-title h3",
				start: "top center",
				end: "bottom +=98%",
			},
		})
	})

	mm.add("(min-width: 768px)", () => {
		gsap.to(".celeste-circle", {
			scrollTrigger: {
				trigger: "#cs-journey__disconnected-title",
				start: "bottom bottom+=300",
				onEnter: () => {
					gsap.to(".celeste-circle", { autoAlpha: 1, duration: 0.5 })
				},
				onLeaveBack: () => {
					gsap.to(".celeste-circle", { autoAlpha: 0, duration: 0.5 })
				},
			},
			autoAlpha: 0,
		})

		gsap.to(".cs-journey__phone", {
			trigger: ".cs-journey__phone",
			start: "top center",
			end: "bottom center",
			onEnter: () => {
				const circle = document.querySelector(".celeste-circle")
				circle.style.transform = "translate(10px, 10px)"
			},
		})
	})

	const connectedListItems = gsap.utils.toArray(
		"#cs-journey__connected-list li"
	)

	const desktopLine = document.querySelector(".journey-svg g"),
		desktopLinePosition = desktopLine.getBoundingClientRect(),
		mobileLine = document.querySelector(".journey-svg-mobile g"),
		mobileLinePosition = mobileLine.getBoundingClientRect(),
		csJourney = document.querySelector(".cs-journey"),
		mobileLinePositionLeft = mobileLinePosition.left - 14

	if (window.innerWidth >= 768) {
		csJourney.style.gridTemplateColumns = `${desktopLinePosition.left}px 2fr 1fr 30%`
	} else if (window.innerWidth < 768) {
		csJourney.style.gridTemplateColumns = `${mobileLinePositionLeft}px 1fr 1fr 30%`
	}

	connectedListItems.forEach((item, index) => {
		gsap.to(item, {
			scrollTrigger: {
				trigger: item,
				start: "top center",
				end: "bottom center",
				scrub: "1",
				onEnter: () => {
					item.classList.add("active")
				},
				onLeaveBack: () => {
					if (item.classList.contains("active")) {
						item.classList.remove("active")
					}
				},
			},
			color: "#1392cd",
		})
	})
}
