// ---------------------------------------------
// ======---------------------------------======
// Video modal
// ======---------------------------------======
// ---------------------------------------------
// import "https://player.vimeo.com/api/player.js"
window.YT.ready(() => {
	const videoModals = document.querySelectorAll("[data-vm-id]")
	const overlay = document.querySelector(".overlay")
	var player

	if (videoModals.length > 0) {
		function onYouTubeIframeAPIReady() {
			player = new YT.Player("celeste-video", {
				playerVars: {
					start: 0,
					origin: `${window.location.origin}`,
				},
			})
		}

		onYouTubeIframeAPIReady()

		function closeModal(modal) {
			modal.close()
		}

		videoModals.forEach(modalBtn => {
			const modalId = modalBtn.getAttribute("data-vm-id")
			const modalDialog = document.querySelector(`#${modalId}`)
			const iframe = modalDialog.querySelector("iframe")
			const iframeSrc = iframe.src
			const videoVars = `&autoplay=1`
			const modalVideoSrc = iframeSrc + videoVars
			const video = document.querySelector(".video-hero__video video")

			modalBtn.addEventListener("click", e => {
				e.preventDefault()
				iframe.src = modalVideoSrc
				modalDialog.showModal()
			})

			modalDialog.querySelector("button").addEventListener("click", e => {
				e.preventDefault()
				iframe.src = iframeSrc
				closeModal(modalDialog)
			})

			modalDialog.addEventListener("click", e => {
				e.stopImmediatePropagation()
				iframe.src = iframeSrc

				closeModal(modalDialog)
			})

			video.addEventListener("click", e => {
				e.preventDefault()
				iframe.src = modalVideoSrc
				modalDialog.showModal()
			})
		})
	}
})
